<template>
  <div class="">

    <!-- Content Header (Page header) -->
    <div class="">
      <!-- Main content -->
      <section class="content">
        <div class="container-fluid">
          <!-- Main row -->
          <div class="row">
            <section
              class="col-lg-12 connectedSortable table-area mt-3 pr-0 pl-0 mb-3"
            >
              <div class="card">
                <div class="card-body table-responsive">
                  <div class="row bor-bot">
                    <div class="col-md-8 pl-0">
                      <h1 class="m-0 text-dark pt-2 pb-3">Manage Coupons</h1>
                    </div>
                    <div class="col-md-4">
                      <div class="text-right pb-3">
                            <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item"><a href="/admin/dashboard">Home</a></li>
                            <li class="breadcrumb-item"><Breadcrumbs/></li>
                            </ol>
                    </div>
                    </div>                   
                  </div>
                  <div class="">
                        <div class="row">
                          
                        <div class="col-lg-8 mr-0 pr-0">
                          <div class="mt-3">
                            <b-form inline>
                            <label class="mr-sm-2 font-weight-normal">Show</label>
                            <b-form-select
                              id="inline-form-custom-select-pref"
                              class="mb-2 mr-sm-2 mb-sm-0"
                              v-model="perPage"
                              :options="viewEntryOptions"
                              size="sm"
                            ></b-form-select
                            >entries
                          </b-form>

                          </div>
                        
                        </div>

                        <div class="col-lg-4  text-right pr-0">
                          <div class=" card-text d-flex align-items-center float-right"> 
                          <div class="mt-3"> 
                          <b-input-group size="sm">
                            <b-form-input
                              id="filter-input"
                              v-model="filter"
                              type="search"
                              placeholder="Search"
                            ></b-form-input>
                          </b-input-group>
                          </div>
                          <div class="mt-3 ml-3">
                              <b-button
                              variant="outline-primary"
                              @click="addRow($event.target)"
                              >Add Coupon</b-button
                            >
                          </div>
                          </div>
                        </div>

                        </div>
                  </div>
                


                  <b-table
                    ref="merchandiseTable"
                    id="merchandise-table"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :items="items"
                    :fields="visibleFields"
                    :busy="!loaded"
                    class="mt-3"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    hover
                    show-empty
                  >
                    <template #cell(actions)="row">
                      <div class="text-left py-0 align-middle">
                        <div class="btn-group btn-group-sm">
                          <button
                            class="btn btn-primary"
                            @click="viewRow(row.item, row.index, $event.target)"
                          >
                            <i class="fas fa-eye"></i>
                          </button>
                          <button
                            class="btn btn-primary ml-1"
                            @click="editRow(row.item, $event.target)"
                          >
                            <i class="fas fa-pencil-alt"></i>
                          </button>
                          <button
                            class="btn btn-danger ml-1"
                            @click="deleteRow(row.item.id)"
                          >
                            <i class="fas fa-trash"></i>
                          </button>
                        </div>
                      </div>
                    </template>
                    <template #empty="scope">
                      <h4>{{ scope.emptyText }}</h4>
                    </template>
                    <template #table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Loading...</strong>
                      </div>
                    </template>
                  </b-table>
                  <br />
                  <b-pagination
                    v-if="loaded"
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                    prev-text="Previous"
                    next-text="Next"
                    aria-controls="merchandise-table"
                    class="float-right custom-color"
                    hide-goto-end-buttons
                  ></b-pagination>
                  <!-- Info modal -->
                  <b-modal
                    :id="infoModal.id"
                    :title="infoModal.title"
                    size="lg"
                    ok-only
                    @hide="resetInfoModal"
                  >
                    <dl class="row">
                      <dt class="col-sm-2">Name</dt>
                      <dd class="col-sm-10">{{ infoModal.content.name }}</dd>

                      <dt class="col-sm-2">Code</dt>
                      <dd class="col-sm-10">{{ infoModal.content.code }}</dd>

                      <dt class="col-sm-2">Status</dt>
                      <dd class="col-sm-10">
                        {{ infoModal.content.status | productStatus }}
                      </dd>

                      <dt class="col-sm-2">Value</dt>
                      <dd class="col-sm-10">{{ infoModal.content.value }}</dd>

                      <dt class="col-sm-2">Start Date</dt>
                      <dd class="col-sm-10">
                        {{ infoModal.content.start_date | formatDate }}
                      </dd>

                      <dt class="col-sm-2">End Date</dt>
                      <dd class="col-sm-10">
                        {{ infoModal.content.end_date | formatDate }}
                      </dd>

                      <dt class="col-sm-2">Discount Type</dt>
                      <dd class="col-sm-10">
                        {{
                          infoModal.content.discount_type == 1
                            ? "Price"
                            : infoModal.content.discount_type
                            ? "Percent"
                            : "--"
                        }}
                      </dd>
                      <dt class="col-sm-2">Coupon Type</dt>
                      <dd class="col-sm-10">
                        {{ infoModal.content.coupon_type != null ? infoModal.content.coupon_type.name : '' }}
                      </dd>
                    </dl>
                  </b-modal>
                  <!-- Edit modal -->
                  <b-modal
                    id="edit-modal"
                    :title="editModal.title"
                    size="lg"
                    ref="modal"
                    @ok="handleOk"
                    @hide="resetEditModal"
                  >
                    <div class="row">
                      <div class="col-md-6">
                        <form ref="form" @submit.stop.prevent="handleSubmit">
                          <div>
                            <label class="pt-4 form-label" for="feedback-name"
                              >Name</label
                            >
                            <b-form-input
                              @keydown="errors.clear('name')"
                              v-model="editModal.content.name"
                              id="feedback-name"
                            ></b-form-input>
                            <b-form-invalid-feedback
                              :state="!errors.has('name')" style="font-size: 14px;"
                            >
                              {{ errors.get("name") }}</b-form-invalid-feedback
                            >
                          </div>

                          <div class="pt-2">
                            <label class="pt-4 form-label" for="feedback-code"
                              >Coupon Code</label
                            >
                            <b-form-input
                              @keydown="errors.clear('code')"
                              v-model="editModal.content.code"
                              id="feedback-product_code"
                            ></b-form-input>
                            <b-form-invalid-feedback
                              :state="!errors.has('code')" style="font-size: 14px;"
                            >
                              {{ errors.get("code") }}</b-form-invalid-feedback
                            >
                          </div>
                          <div class="pt-2">
                            <label
                              class="pt-4 form-label"
                              for="feedback-discount_type"
                              >Discount Type</label
                            >
                            <b-form-group>
                              <b-form-radio
                                v-model="editModal.content.discount_type"
                                name="discount_type"
                                value="1"
                                >Price</b-form-radio
                              >
                              <b-form-radio
                                v-model="editModal.content.discount_type"
                                name="discount_type"
                                value="2"
                                >Percent</b-form-radio
                              >
                            </b-form-group>
                            <span class="text-sm text-danger">{{
                              errors.get("discount_type")
                            }}</span>
                          </div>
                          <div class="pt-2">
                            <label class="pt-4 form-label" for="feedback-status"
                              >Status</label
                            >
                            <b-form-group>
                              <b-form-radio
                                v-model="editModal.content.status"
                                name="status"
                                value="1"
                                >Active</b-form-radio
                              >
                              <b-form-radio
                                v-model="editModal.content.status"
                                name="status"
                                value="0"
                                >In-Active</b-form-radio
                              >
                            </b-form-group>
                            <span class="text-sm text-danger">{{
                              errors.get("status")
                            }}</span>
                          </div>
                        </form>
                      </div>
                      <div class="col-md-6">
                        <div>
                          <label class="pt-4 form-label" for="feedback-value"
                            >Value</label
                          >
                          <b-form-input
                            @keydown="errors.clear('value')"
                            v-model="editModal.content.value"
                            id="feedback-value"
                            type="number"
                          ></b-form-input>
                          <b-form-invalid-feedback
                            :state="!errors.has('value')" style="font-size: 14px;"
                          >
                            {{ errors.get("value") }}</b-form-invalid-feedback
                          >
                        </div>

                        <div class="pt-2">
                          <label
                            class="pt-4 form-label"
                            for="feedback-start-date"
                            >Start Date</label
                          >
                          <div class="input-group birthdate">
                            <div class="birthdaypicker">
                              <date-pick
                                v-model="editModal.content.start_date"
                                :format="'YYYY-MM-DD'"
                                :displayFormat="'MM/DD/YYYY'"
                              ></date-pick>
                            </div>
                            <span class="text-sm text-danger">{{
                              errors.get("start_date")
                            }}</span>
                          </div>
                        </div>

                        <div class="pt-2">
                          <label
                            class="pt-4 form-label"
                            for="feedback-start-date"
                            >End Date</label
                          >
                          <div class="input-group birthdate">
                            <div class="birthdaypicker">
                              <date-pick
                                v-model="editModal.content.end_date"
                                :format="'YYYY-MM-DD'"
                                :displayFormat="'MM/DD/YYYY'"
                              ></date-pick>
                            </div>
                            <span class="text-sm text-danger">{{
                              errors.get("end_date")
                            }}</span>
                          </div>
                        </div>

                        <div class="pt-2">
                            <label class="pt-4 form-label" for="feedback-status"
                              >Coupon Type</label
                            >
                            <select name="coupon_type_id" id="coupon_type_id" class="form-control" v-model="editModal.content.coupon_type_id">
                              <option value="">Select</option>  
                              <option v-for="(item, index) in couponTypes" :key="'coupon-type-'+index" :value="item.id">{{ item.name }}</option>
                            </select>
                            <span class="text-sm text-danger">{{
                              errors.get("coupon_type_id")
                            }}</span>
                          </div>
                      </div>
                      <div class="col-md-6" v-if="subdomain=='alliancefastpitch'">
                        <div class="pt-2">
                          <label class="pt-4 form-label" for="feedback-membership_id"
                          >Membership Type</label
                          >
                          <select name="coupon_membership_id" id="coupon_membership_id" class="form-control" v-model="editModal.content.coupon_membership_id">
                            <option value="">Select</option>
                            <option v-for="(item, index) in membershipTypes" :key="'membership-'+index" :value="item.id">{{ item.name }}</option>
                          </select>
                          <span class="text-sm text-danger">{{
                              errors.get("coupon_membership_id")
                            }}</span>
                        </div>
                      </div>
                    </div>
                  </b-modal>
                </div>
              </div>
            </section>
          </div>
          <!-- /.row (main row) -->
        </div>
        <!-- /.container-fluid -->
      </section>
    </div>
    <!-- /.content -->

  </div>
</template>

<script>

import axios from "axios";
import Errors from "../../Errors.js";
import moment from "moment";
import DatePick from "vue-date-pick";

let cachedData = {};

export default {
  name: "coupons",
  components: { DatePick },

  data() {
    return {
      loaded: false,
      perPage: 10,
      currentPage: 1,
      errors: new Errors(),
      fields: [
        {
          key: "id",
          label: "ID",
          sortable: true,
        },
        {
          key: "name",
          sortable: true,
        },
        {
          key: "code",
          sortable: true,
        },
        {
          key: "status",
          sortable: true,
          formatter: (value, key, item) => {
            return value ? "Active" : "Inactive";
          },
        },
        {
          key: "value",
          sortable: true,
        },
        {
          key: "start_date",
          sortable: true,
          formatter: (value) => {
            return moment(String(value)).format("MM/DD/YYYY");
          },
        },
        {
          key: "end_date",
          sortable: true,
          formatter: (value) => {
            return moment(String(value)).format("MM/DD/YYYY");
          },
        },
        {
          key: "coupon_type.name",
          label: "Coupon Type",
          sortable: true,
          formatter: (value) => {
            return value;
          },
        },
        {
          key: "coupon_membership_name",
          sortable: true,
        },
        {
          key: "actions",
          sortable: false,
        },
      ],
      items: [],
      viewEntryOptions: [
        { value: 5, text: "5" },
        { value: 10, text: "10" },
        { value: 20, text: "20" },
      ],
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      editModal: {
        editing: true,
        id: "edit-modal",
        title: "",
        content: "",
      },
      couponTypes : [],
      membershipTypes : []
    };
  },

  filters: {
    productStatus(value) {
      return value ? "Active" : "Inactive";
    },
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("MM/DD/YYYY");
      }
    },
  },

  methods: {
     formatlongDate(datevalue)
        {
            var date = moment(datevalue);
            if(date.isValid()){
                return date.format('MM/DD/YYYY');
            } else {
                return 'N/A';
            }
        },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    addRow(button) {
      (this.editModal.editing = false),
        (this.editModal.title = "Add New Coupon");
      let id = localStorage.getItem("id");
      this.editModal.content = {
        name: "",
        code: "",
        organization_id: 1,
        updated_by: id,
        discount_type: 1,
        status: 1,
        coupon_type_id: "",
        coupon_membership_id:""
      };
      this.$root.$emit("bv::show::modal", "edit-modal", button);
    },
    viewRow(item, index, button) {
      this.infoModal.title = item.name;
      this.infoModal.content = item;
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    editRow(item, button) {
      (this.editModal.editing = true),
        (this.editModal.title = "Update " + item.name);
      this.editModal.content = item;
      console.log(item);
      this.$root.$emit("bv::show::modal", "edit-modal", button);
    },
    deleteRow(id) {
      if(!confirm("Are you sure to delete this item?")){
        return true
      }else{
      axios
        .delete(process.env.VUE_APP_URL + "api/coupons/delete/" + id)
        .then((response) => {
          alert("Coupon deleted!");
          this.resetEditModal();
        })
        .catch((error) => {
          (error) => (this.errors = error.response.data);
        });
      return false;
      }
    },

    //Modals
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    resetEditModal() {
      this.errors.clear();
      this.loaded = false;
      axios
        .get(process.env.VUE_APP_URL + "api/coupons/index")
        .then((response) => {
          cachedData = response.data.coupons;
          this.items = cachedData;
          this.loaded = true;
        })
        .catch((error) => (this.errors = error.response.data));
      this.loaded = true;
    },

    // Submitting
    onFail(errors) {
      this.errors.record(errors);
    },

    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      let url = process.env.VUE_APP_URL + "api/coupons";
      let requestType = "post";

      if (this.editModal.editing) {
        url = process.env.VUE_APP_URL + "api/coupons/update/" + this.editModal.content.id;
        requestType = "patch";
      }

      this.handleSubmit(requestType, url)
        .then(() => {
          this.$nextTick(() => {
            var message = this.editModal.editing ? "saved" : "created";
            alert("Coupon has been " + message + ".");
            this.$bvModal.hide("edit-modal");
          });
        })
        .catch(() => false);
    },

    handleSubmit(requestType, url) {
      return new Promise((resolve, reject) => {
        axios[requestType](url, this.editModal.content)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            this.onFail(error.response.data.errors);
            reject(error.response.data);
          });
      });
    },
    isFutureDate(date) {
      const currentDate = new Date();
      return date > currentDate;
    },
    loadCouponTypes() {
      axios.get(this.basePath + "api/coupons/type", {headers: this.headers})
          .then((response) => {
            if (response.data.status == 'success') {
              const resp = response.data.data;
              this.couponTypes = resp;
              console.log(this.couponTypes);
            }
          }).catch((error) => {
        console.log(error);
      });
    },
    loadmembershipTypes()
    {
        axios.get(this.basePath + "api/coupons/membership-type", {headers: this.headers})
          .then((response) => {
            if (response.data.status == 'success') {
              this.membershipTypes = response.data.data;
              console.log(this.membershipTypes);
            }
          }).catch((error) => {
        console.log(error);
      });
    }
  },

  created() {
    if (Object.keys(cachedData).length === 0) {
      axios
        .get(process.env.VUE_APP_URL + "api/coupons/index")
        .then((response) => {
          cachedData = response.data.coupons;
          this.items = cachedData;
          this.loaded = true;
        })
        .catch((error) => (this.errors = error.response.data));
      return false;
    }
    this.items = cachedData;
    this.loaded = true;
  },
  mounted(){
    this.loadCouponTypes();
    this.loadmembershipTypes();
  },
  computed: {
    rows() {
      return this.items.length;
    },
    visibleFields() {
      return this.fields.filter(field => {
        if(this.subdomain != 'alliancefastpitch'){
          if(['coupon_membership_name'].indexOf(field.key) == -1) return field;
        }
        else {
          return field;
        }
      });
    }
  },
};
</script>
